<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import { useNotification } from '@/composables/notification';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

const route = inject('route');
const { t } = useI18n();
const notification = useNotification();

const props = defineProps({
    showing: Boolean,
    shift: Object,
});

const emits = defineEmits(['update:showing', 'markedAsNoShow']);
const closeForm = () => emits('update:showing', false);

const form = useForm({
    shift_id: props.shift.id,
});

const sendForm = () => {
    form.post(route('staff.mark-shift-as-no-show.store'), {
        preserveScroll: true,
        onSuccess: () => {
            closeForm();
            emits('markedAsNoShow');
        },
        onError: () => {
            notification.show(t('Something went wrong, please try again.'), new Date().getTime(), 'error');
        },
    });
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm" class="sm:max-w-[800px]">
        <template #default>
            <div class="text-[14px] mt-4">
                {{ $t("Are you sure Fl@xr didn't show up? This action cannot be undone.") }}<br />
                {{
                    $t('Please note that processed invoices or export files to Easyflex will need to be fixed manually')
                }}
            </div>
            <div class="flex justify-end gap-4">
                <Button danger type="button" :text="$t('Yes')" @click="sendForm" />
                <Button orange type="button" :text="$t('No, go back')" @click="closeForm" />
            </div>
        </template>
    </Modal>
</template>
